<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="majorSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm ngành học' : 'Cập nhật ngành học'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên ngành học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tên ngành học"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetItem.name"
              name="name"
              placeholder="Nhập tên ngành học"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="code">
          <template v-slot:label>
            Mã ngành học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mã ngành học"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetItem.code"
              name="code"
              placeholder="Nhập mã ngành học"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="department">
          <template v-slot:label>
            Khoa/Bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoa/Bộ môn"
            rules="required"
          >
            <v-select
              v-model="targetItem.departmentId"
              :options="departments"
              :reduce="option => option.value"
              @input="onChangeDepartments"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Là chuyên ngành con của"
          label-for="parentId"
        >
          <v-select
            v-model="targetItem.parentId"
            :options="majors"
            :reduce="option => option.value"
          />
        </b-form-group>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetItem.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="orderNo">
          <template v-slot:label>
            Thứ tự <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thứ tự"
            rules="required"
          >
            <b-form-input
              id="orderNo"
              v-model="targetItem.orderNo"
              name="orderNo"
              placeholder="Nhập thứ tự"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('majorSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'MajorSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        organizationId: getUser().orgId,
        departmentId: null,
        status: 1,
        orderNo: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'major/statuses',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
    }),
    statusOptions() {
      return this.statuses
    },
    // departmentOptions() {
    //   return this.departments.map(item => ({ value: item.id, label: item.name }))
    // },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createMajors: 'major/createMajors',
      updateMajors: 'major/updateMajors',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getDepartments({ organizationId: getUser().orgId })
      if (this.item) {
        this.targetItem = { ...this.item }
        await this.getMajors({
          organizationId: getUser().orgId,
          departmentId: this.targetItem.departmentId,
        })
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        organizationId: getUser().orgId,
        departmentId: null,
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createMajors(this.targetItem)
            : await this.updateMajors(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('majorSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async onChangeDepartments(event) {
      // this.targetItem.parentId = null
      await this.getMajors({
        organizationId: getUser().orgId,
        departmentId: event,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
